import { Container, Row, Col } from "react-bootstrap";
import { useNavigate  } from "react-router-dom";
import 'animate.css';
// import TrackVisibility from 'react-on-screen';
// import  PlayList  from "./PlayList";

// export const ProjectCard = ({ itemData, videoId, title, description, imgUrl, itemDetails, handleClick }) => {
export const Projects = ({itemData, playLists, handleClick}) => {
  const navigate = useNavigate();
  //console.log("playLists:" , playLists)

  // console.log("project callback: ", handleClick);
  // console.log("project playLists: ",playLists);

  // const passSelectedVideo=(video)=>{
  //   console.log("PROJECT: pass on selected video",video,handleClick)
  //   handleClick(video);
  // }
  // const passSelectedCategory=(ci)=>{
  //   handleClick(ci);
  // }
  const clickCategory =(categoryItem)=>{
    console.log("you clicked ", categoryItem.id);
    navigate("/projects/" + categoryItem.id)
   // passSelectedCategory(categoryItem);
  }
  const renderPlaylists = 
      playLists.map((item,index) =>{
       //console.log("playlistitem",item);
       return <Col key={index} lg={4} sm={6} md={4}>
                    <div onClick={(e)=>{clickCategory(item)}} className="proj-imgbx" >
                      <img  src={item.snippet.thumbnails.high.url} alt="thumbnail"/>
                      <div className="proj-txtx">
                        <div>{item.snippet.title}</div>
                        {/* <span>{description}</span> */}
                      </div>
                    </div>
                    </Col>
        // return <PlayList  handleSelectedVideo={passSelectedVideo}  playListItem={item} key={"pl" + index}></PlayList>
      })  



  return (

      <Container>
      <Row className="align-items-center">
        {/* <MailchimpForm /> */}
        <Col size={12} lg={12} sm={12}>
        <section className="project" id="project">
            {/* <TrackVisibility> */}
              {/* {({ isVisible }) => */}
              <div > {/* className={isVisible ? "animate__animated animate__fadeIn": ""} */}
                <h2>Projects</h2>
                <p>Check out my latest work</p>
               <Container>
                <Row>
                    {playLists && renderPlaylists} 
                </Row>
                </Container>
              </div>
              {/* } */}
            {/* </TrackVisibility> */}
            </section>
          </Col>
        </Row>
      </Container>

  )
}
