import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/AI9.jpg";
import 'animate.css';
import TextTransition,{presets} from "react-text-transition";

const SKILLS=['Adobe Creative Suite','Video Post-Production','Video Editing','Motion Graphics', 'Color Grading','Audio Editing','Digital Media','Brand Identity']
// import TrackVisibility from 'react-on-screen';

export const Banner = () => {
const [skillIndex,setSkillIndex]= useState(0);
useEffect(()=>{
  const intervalId = setInterval(
    ()=> setSkillIndex((index)=>index+1),
    3000,
  )
  return ()=> clearTimeout(intervalId);

},[]);
  // const [loopNum, setLoopNum] = useState(0);
  // const [isDeleting, setIsDeleting] = useState(false);
  // const [text, setText] = useState('');
  // const [delta, setDelta] = useState(300 - Math.random() * 100);
  // const [index, setIndex] = useState(1);
  // const toRotate = [ "Storyteller", "Video Editor" ];
  // const period = 2000;

  // useEffect(() => {
  //   let ticker = setInterval(() => {
  //     tick();
  //   }, delta);

  //   return () => { clearInterval(ticker) };
  // }, [text])

  // const tick = () => {
  //   let i = loopNum % toRotate.length;
  //   let fullText = toRotate[i];
  //   let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

  //   setText(updatedText);

  //   if (isDeleting) {
  //     setDelta(prevDelta => prevDelta / 2);
  //   }

  //   if (!isDeleting && updatedText === fullText) {
  //     setIsDeleting(true);
  //     setIndex(prevIndex => prevIndex - 1);
  //     setDelta(period);
  //   } else if (isDeleting && updatedText === '') {
  //     setIsDeleting(false);
  //     setLoopNum(loopNum + 1);
  //     setIndex(1);
  //     setDelta(500);
  //   } else {
  //     setIndex(prevIndex => prevIndex + 1);
  //   }
  // }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>

              <div >
                <span className="tagline">Welcome to my Portfolio</span>
                <h1>Gavin Herman</h1>
                  <p>Versatile video editor with a passion for post-production and a demonstrated history of working in the digital media industry. Committed to creating stories that captivate audiences and developing visually engaging experiences. Strong work ethic, dedication to excellence, and a desire to learn new skills. </p>
                  <div className="skillAnimation">My Skills &nbsp;&nbsp;<TextTransition inline springConfig={presets.default}><span className="skillBlock">{SKILLS[skillIndex % SKILLS.length]}</span></TextTransition></div>

              </div>
            
          </Col>
          <Col xs={12} md={6} xl={5}>
            <div style={{position:"relative"}}>
    
                <div className="profileBox">
                  <div id="banner_photo" >
                    <img src={headerImg} width="100%" alt="Header Img"/>
                  </div>
                  <svg id="anim_circle_1" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="60" cy="60" r="60" fill="#185246"/>
                  </svg>
                  <svg id="anim_circle_2" width="168" height="168" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="84" cy="84" r="84" fill="#92e0d1"/>
                  </svg>
                </div>
            </div>
          </Col>
        </Row>
      </Container>

    </section>
  )
}
