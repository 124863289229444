// import { useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import contactImg from "../assets/img/contact-img.svg";
// import 'animate.css';
// import TrackVisibility from 'react-on-screen';
import navIcon1 from '../assets/img/nav-icon1.svg';
// import navIcon2 from '../assets/img/nav-icon2.svg';
// import navIcon3 from '../assets/img/nav-icon3.svg';
import emailIcon from '../assets/img/email.svg';
export const Contact = () => {
  // const formInitialDetails = {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   phone: '',
  //   message: ''
  // }
  // const [formDetails, setFormDetails] = useState(formInitialDetails);
  // const [buttonText, setButtonText] = useState('Send');
  // const [status, setStatus] = useState({});

  // const onFormUpdate = (category, value) => {
  //     setFormDetails({
  //       ...formDetails,
  //       [category]: value
  //     })
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setButtonText("Sending...");
  //   let response = await fetch("https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send", {
  //     method: "POST",
  //     headers: {
  //       'content-type': 'application/json',
  //       'X-RapidAPI-Key': '63086ad54fmshe3af895ad4d8eb6p19e16ejsnebc622ab8f26',
  //       'X-RapidAPI-Host': 'rapidprod-sendgrid-v1.p.rapidapi.com'
  //     },
  //     body: //JSON.stringify(formDetails),
  //     {
  //       personalizations: [
  //         {
  //           to: [
  //             {
  //               email: 'doug.herman@outlook.com'
  //             }
  //           ],
  //           subject: 'PORTFOLIO CONTACT'
  //         }
  //       ],
  //       from: {
  //         email: 'from_address@example.com'
  //       },
  //       content: [
  //         {
  //           type: 'text/plain',
  //           value: 'Hello, World!'
  //         }
  //       ]
  //     }
  //   });
  //   setButtonText("Send");
  //   let result = await response.json();
  //   setFormDetails(formInitialDetails);
  //   if (result.code == 200) {
  //     setStatus({ succes: true, message: 'Message sent successfully'});
  //   } else {
  //     setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
  //   }
  // };

 
  return (
    <section className="contact align-items-center" id="connect">
                     <h2>Contact me!</h2>
                     <div className="social-icon">
                <a href="https://www.linkedin.com/in/gavin-herman/"><img src={navIcon1} alt="Linked In" /></a>
                <a href="mailto:gavinkherman@gmail.com"><img src={emailIcon} alt="" /></a> 
                {/* <a href="https://www.instagram.com/vontentdigital/"><img src={navIcon3} alt="Vontent Digital" /></a>  */}
              </div>
    </section>
  )
}
