import { Col } from "react-bootstrap";

export const ProjectCard = ({ itemData, videoId, title, description, imgUrl, itemDetails, handleClick }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div onClick={(e)=>{handleClick(itemData)}} className="proj-imgbx embed-responsive embed-responsive-16by9" style={{backgroundImage:imgUrl, backgroundSize:"fill"}}>
        <img className="embed-responsive-item" src={imgUrl} alt="description"/>
        <div className="proj-txtx">
          <div>{title}</div>
          {/* <span>{description}</span> */}
        </div>
      </div>
    </Col>
  )
}
