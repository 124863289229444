const PLAYLISTS = [
    {
        "kind": "youtube#playlist",
        "etag": "RuU--w8raO5q0RytTrWB5dzHb_g",
        "id": "PLBGPD-hPBazjRXLlKfnPtaAGzpNfyD9re",
        "snippet": {
            "publishedAt": "2023-06-06T17:05:46Z",
            "channelId": "UCGCtHifLO6nlZTkR65dMwLw",
            "title": "CreatorWise",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/WZAOD4HOY_8/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/WZAOD4HOY_8/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "./assets/cw.png",
                    "width": 480,
                    "height": 360
                },
                "standard": {
                    "url": "https://i.ytimg.com/vi/WZAOD4HOY_8/sddefault.jpg",
                    "width": 640,
                    "height": 480
                },
                "maxres": {
                    "url": "https://i.ytimg.com/vi/WZAOD4HOY_8/maxresdefault.jpg",
                    "width": 1280,
                    "height": 720
                }
            },
            "channelTitle": "Gavin Herman",
            "localized": {
                "title": "CreatorWise",
                "description": ""
            }
        }
    },
    {
        "kind": "youtube#playlist",
        "etag": "qfz5-n7dadCVuQBsr2DaKeCkwZc",
        "id": "PLLpwnLJ1JV9K5kkWyrAhjD8U08PQNSE9H",
        "snippet": {
            "publishedAt": "2023-06-06T17:05:16Z",
            "channelId": "UCGCtHifLO6nlZTkR65dMwLw",
            "title": "Interview/Documentary style",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/vy0D6F2SfIM/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/vy0D6F2SfIM/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "./assets/int-doc.png",
                    "width": 480,
                    "height": 360
                },
                "standard": {
                    "url": "https://i.ytimg.com/vi/vy0D6F2SfIM/sddefault.jpg",
                    "width": 640,
                    "height": 480
                },
                "maxres": {
                    "url": "https://i.ytimg.com/vi/vy0D6F2SfIM/maxresdefault.jpg",
                    "width": 1280,
                    "height": 720
                }
            },
            "channelTitle": "Gavin Herman",
            "localized": {
                "title": "Interview/Documentary style",
                "description": ""
            }
        }
    },
    {
        "kind": "youtube#playlist",
        "etag": "RdYOreav2kG57TMMoAuu6d2oOzY",
        "id": "PLlMvDaznTfwnH8NT6NV2UONPw3k39Bh0j",
        "snippet": {
            "publishedAt": "2023-07-12T18:53:26Z",
            "channelId": "UCGCtHifLO6nlZTkR65dMwLw",
            "title": "Educational content channel",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/TP3YY9gO_Uk/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/TP3YY9gO_Uk/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "./assets/gh.png",
                    "width": 480,
                    "height": 360
                },
                "standard": {
                    "url": "https://i.ytimg.com/vi/TP3YY9gO_Uk/sddefault.jpg",
                    "width": 640,
                    "height": 480
                },
                "maxres": {
                    "url": "https://i.ytimg.com/vi/TP3YY9gO_Uk/maxresdefault.jpg",
                    "width": 1280,
                    "height": 720
                }
            },
            "channelTitle": "Gavin Herman",
            "localized": {
                "title": "Educational content channel",
                "description": ""
            }
        }
    },
    {
        "kind": "youtube#playlist",
        "etag": "ZPDAIHcdK4GJaotIaoFfSeIn4BM",
        "id": "PLLpwnLJ1JV9LIQvmPfeEsDZdMr30sK9Cw",
        "snippet": {
            "publishedAt": "2023-06-06T17:06:17Z",
            "channelId": "UCGCtHifLO6nlZTkR65dMwLw",
            "title": "DragonFruit Media",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/img/no_thumbnail.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/img/no_thumbnail.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "./assets/dfm.png",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Gavin Herman",
            "localized": {
                "title": "DragonFruit Media",
                "description": ""
            }
        }
    },
   
    
    {
        "kind": "youtube#playlist",
        "etag": "UTSXgKDgBdaSjlhcgAkPG84Zvx0",
        "id": "PLLpwnLJ1JV9L7OgdknUloAayOyNew5xRn",
        "snippet": {
            "publishedAt": "2023-06-06T17:07:04Z",
            "channelId": "UCGCtHifLO6nlZTkR65dMwLw",
            "title": "Filmmaking",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "./",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/img/fm.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "./assets/fm.png",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Gavin Herman",
            "localized": {
                "title": "Filmmaking",
                "description": ""
            }
        }
    },
    {
        "kind": "youtube#playlist",
        "etag": "owLxZczwWPWUezPekt8g6jcfDZY",
        "id": "PLLpwnLJ1JV9Ln61ErMt-Zy7IrL8sASLr3",
        "snippet": {
            "publishedAt": "2023-06-06T17:06:52Z",
            "channelId": "UCGCtHifLO6nlZTkR65dMwLw",
            "title": "Sports content",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/img/no_thumbnail.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/img/no_thumbnail.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "./assets/ath.png",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Gavin Herman",
            "localized": {
                "title": "Sports content",
                "description": ""
            }
        }
    },



]
export default PLAYLISTS
