import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";

// import logo from "../assets/img/logo.svg";
// import navIcon1 from "../assets/img/nav-icon1.svg";
// import navIcon2 from "../assets/img/nav-icon2.svg";
// import navIcon3 from "../assets/img/nav-icon3.svg";

export const HeroVideo = () => {
  return (
    <div className="heroVideoContainer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} lg={12} sm={12}>
          <div className="YouTubeContainer heroVideo" style={{backgroundColor:"#"}}>
              <iframe allowFullScreen className="responsive-iframe" src={"https://www.youtube.com/embed/u9IDBmTR9Yk"}  title="YouTube video player"></iframe>
            </div>
          </Col>
        </Row>
      </Container>
      </div>

  )
}
