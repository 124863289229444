// import logo from '../logo.svg';
import '../App.css';
import React  from 'react';
// import { useEffect } from 'react';
// import {useParams} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './NavBar';
import  {Banner}  from "./Banner";
// import  {Skills}  from "./Skills";
import  {Projects}  from "./Projects";
import  {Contact}  from "./Contact";
import  {Footer}  from "./Footer";
// import youtube from '../apis/youtube';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import playlists from '../playlists.js';
import { HeroVideo } from './HeroVideo';
// import PlayList from './components/PlayList';
// import youtube from '../apis/youtube';
function Home(props) {
  // const [showModal, setShowModal] = useState(false);

  
  // useEffect(() => {
  //   async function GetPlaylists(termFromSearchBar){
  //     //console.log("searching");
  //     const response = await youtube.get('/playlists', {
  //         params: {
  //             part:"snippet",
  //             channelId:"UCGCtHifLO6nlZTkR65dMwLw"// "UC2PtV8wACZwd9u3t_ycwTaA" //
  //         }
  //     })
  //     console.log("playlists",response.data.items);
  //     this.setState({
  //         playlists: response.data.items
  //     })
  //   };
  //   GetPlaylists("");
  // }, []);


  return (
                 <div className="App">
                  <div style={{backgroundColor:"#fff"}}>
                    <NavBar />
                    <Banner />
                    <HeroVideo />
                   {/* <Skills/> */}
                   <Projects playLists={playlists}/>
                   <Contact />
                   <Footer />
                   </div>
                  </div>
    );
  }
  //itemData={"test"} handleClick={this.handleClick} 
  export default Home;

// class Home extends React.Component {
//   constructor(props) {
//     super(props);
//     // let {did} = useParams();
//     this.handleClick = this.handleClick.bind(this)
//     this.state = {    
//       playlists:playlists,
//       showPlaylist:false,
//       selectedCategory:null,
//       selectedVideo:null,
//         };
//   }

//   handleCategoryClick = (ci) =>{
//     this.setState({selectedCategory:ci,showPlaylist:true})    
//     this.forceUpdate();
//   }
  
//   handleClick = (video) => {
//     console.log("APP: you clicked", video)
//     this.selectedVideo = video;
//     this.modalShow = true;
//     this.forceUpdate();
//     //this.setState({selectedVideo:video})
//   }

//   modalShow=false;
//   selectedVideo = {
//     snippet:{
//       title:"placeholder",
//       resourceId:{
//         videoId:"placeholder"
//       },
//       description:"placeholder"
//     }
//   };

  // componentDidMount() {
  //   //console.log("did mount")
  //   //this.GetPlaylists("");
  // }
  // function GetPlaylists = async (termFromSearchBar) => {
  //   //console.log("searching");
  //   const response = await youtube.get('/playlists', {
  //       params: {
  //           part:"snippet",
  //           channelId:"UCGCtHifLO6nlZTkR65dMwLw"// "UC2PtV8wACZwd9u3t_ycwTaA" //
  //       }
  //   })
  //   console.log("playlists",response.data.items);
  //   this.setState({
  //       playlists: response.data.items
  //   })
  // };

//   changeModal = (newState)=> {
//     this.modalShow = newState;
//     console.log("changing modal", newState)
//     if (newState===false){
//       this.selectedVideo={
//         snippet:{
//           title:"placeholder",
//           resourceId:{
//             videoId:"placeholder"
//           },
//           description:"placeholder"
//         }
//       };
//     }
// //    this.setState({showModal:newState})
//     this.forceUpdate();
//   }


//   render () {
//     return (
      
//         <div className="App">
//           {/* {this.showPlaylist &&
//           <>
//           <PlayList></PlayList>
//           </>
//           } */}
//           {!this.showPlaylist &&
//           <>
//           <NavBar />
//           <Banner />
//           <Skills />
          
//           <Modal
//             show={this.modalShow}
//             size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//           >
//             {/* <Modal.Header>
//               <Modal.Title id="contained-modal-title-vcenter">
//               </Modal.Title>
//             </Modal.Header> */}
//             <Modal.Body>
//             <div className="YouTubeContainer">
//               <iframe className="responsive-iframe" src={"https://www.youtube.com/embed/"+ this.selectedVideo.snippet.resourceId.videoId}  title="YouTube video player" frameborder="0"  allowfullscreen></iframe>
//             </div>
           
           
//             {/* <div style={{textAlign:"center"}} className=" embed-responsive embed-responsive-16by9"> */}           
//             {/* <video className="embed-responsive-item" src={"https://www.youtube.com/embed/"+ this.selectedVideo.snippet.resourceId.videoId} style={{width:'100%'}} controls={true}></video> */}
//             {/* </div> */}

//             <h3>{this.selectedVideo.snippet.title}</h3>
//             <div style={{fontWeight:"100",fontSize:"14px"}}>{this.selectedVideo.snippet.description}</div>
//             </Modal.Body>
//             <Modal.Footer>
//             <Button
//               onClick={(e) => {
//                 this.changeModal(false)}
//               }>Close</Button>
//             </Modal.Footer>
//            </Modal>
                
//           <Projects itemData={"test"} handleClick={this.handleClick} playLists={this.state.playlists}/>
//           <Contact />
//           <Footer />

//           </>
//           }
//                   </div>
//      );
//   }
// }

// export default Home;
