import './App.css';
import React from 'react';
import { HashRouter as Router, Routes, Route} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './components/Home';
import ProjectPage from './components/ProjectPage';

function App(props) {

  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<Home {...props}/>}></Route>
      <Route exact path="/projects/:pid" element={<ProjectPage {...props}/>}></Route>
      <Route path="/*" element={<Home {...props}/>}></Route>
      <Route path="*" element={<Home {...props}/>}></Route>
      
      {/* <Route exact path="/dashboards" element={<IoTDashboards {...props}/>}></Route>
      <Route exact path="/digitaltwins" element={<IoTDigitalTwins {...props}/>}></Route> */}
    </Routes>
  </Router>

  );
}

export default App;

// class App extends React.Component {
//   constructor(props) {
//     super(props);

//     this.handleClick = this.handleClick.bind(this)
//     this.state = {   
//         detailId: false,
//         property:true
//         };
//   }

  
//   handleClick = (video) => {
//     console.log("APP: you clicked", video)
//     //this.setState({selectedVideo:video})
//   }

//   modalShow=false;


//   componentDidMount() {
//     //console.log("did mount")
//     //this.GetPlaylists("");
//   }


//   render () {
//     return (
//       <Router>
//         <Routes>
//           <Route Path="/" element={<Home/>}></Route>
//         </Routes>
//       </Router>
//      );
//   }
// }

// export default App;
