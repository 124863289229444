import React,{useEffect,useState} from 'react';
import {useParams,useNavigate} from 'react-router-dom';
import { Container, Row } from "react-bootstrap";
import 'animate.css';
import youtube from '../apis/youtube';
import { ProjectCard } from './ProjectCard';
import Button from 'react-bootstrap/Button';
import playlists from '../playlists.js';
import Modal from 'react-bootstrap/Modal';
import placeholder from "../assets/img/placeholder.png";

function ProjectPage(props) {
    const [playlistItems, setPlayListItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedVideo,setSelectedVideo]= useState({});
    const [highlightIndex,setHighlightIndex]=useState(0);
    const navigate = useNavigate();

    let {pid} = useParams();
    const playlistObject = playlists.find((element => element.id === pid))

    function randomIntFromRange(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
      }

    useEffect(() => {
    async function GetVideos(){
        // console.log("searching ", this.state.playList);
        const response = await youtube.get('/playlistItems', {
            params: {
                part:"snippet",
                playlistId: pid,
                maxResults:50
            }
        })
        setPlayListItems(response.data.items)
        let hi = randomIntFromRange(0,response.data.items.length-1);
        setHighlightIndex(hi)
        console.log("searching ", "index " + hi, response.data.items);
        //console.log("background:" + playlistItems[0].snippet.thumbnails.maxres.url)
        // this.setState({
        //     videos: response.data.items
        // })
    };
    GetVideos();
   // console.log(playlistObject)
    }, [pid]);
//     return (
//     <>ProjectPage Page:{pid}<br/>
//         <div>{playlistItems.length} videos</div>
//     </>
//   );
function handleClick(e){
    setSelectedVideo(e);
    setShowModal(true);
    //console.log("you clicked", e)
}
function goBack(e){
    //console.log("clicked go back");
    navigate("/");
}
function renderBackground(){
  if (playlistItems.length>0 && playlistItems[highlightIndex].snippet.thumbnails.maxres){
    return(<img src={playlistItems[highlightIndex].snippet.thumbnails.maxres.url} width="100%" alt="Header Img"/>)
  } else return(<></>)
}

return (
<div style={{backgroundColor:"#011A28",paddingBottom:"200px"}}>
    <div style={{width:"100%",position:"absolute",padding:"12px", top:0,left:0,textAlign:"Left",zIndex:25}}>
    <Button style={{backgroundColor:"#00A3FF"}} variant="primary" onClick={goBack}>Back</Button>
    </div>
    {playlistItems.length>0 && 
      <div className="projectbannerBG mask1" style={{position:'absolute',top:0,left:0,right:0}}> 
          {renderBackground()}
      </div>
    }
    
    <section className="projectbanner" id="ProjectsPage" style={{zIndex:15}}>
        <div style={{width:"75%", padding:"0 20px"}}>
        {/* <span className="tagline"></span> */}
        <h1>{playlistObject.snippet.title}</h1>
            <p>{playlistObject.snippet.description}</p>
        </div>
    </section>

            <section className="projectList">
              {playlistItems.length > 0 && 
               <Container>
               <Row>
                   {
                   playlistItems.map((item,index) =>{
                     // console.log("playlistitem",item);
                     //<span key={"v" + index} href="#"  onClick={(e)=>{this.props.handleSelectedVideo(item)}} >
                     if (item.snippet.thumbnails.medium){
                      return <ProjectCard handleClick={handleClick} itemData={item}  key={"v" + index} videoID={item.snippet.resourceId.videoId}  title={item.snippet.title} description={item.snippet.title} imgUrl={item.snippet.thumbnails.medium.url} ></ProjectCard>
                     } else {
                      console.log("cant find thumbnail", item)
                      return <ProjectCard handleClick={handleClick} itemData={item}  key={"v" + index} videoID={item.snippet.resourceId.videoId}  title={item.snippet.title} description={item.snippet.title} imgUrl={placeholder} ></ProjectCard>
                     }
                   })
               } 
               </Row>
               </Container>
               }

             </section>
            {selectedVideo.snippet &&
            <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
            <div className="YouTubeContainer">
              <iframe allowFullScreen className="responsive-iframe" src={"https://www.youtube.com/embed/"+ selectedVideo.snippet.resourceId.videoId}  title="YouTube video player"></iframe>
            </div>
            <h3 style={{paddingTop:"20px",color:"#fff"}}>{selectedVideo.snippet.title}</h3>
            <div style={{fontWeight:"100",fontSize:"14px",color:"#fff"}}>{selectedVideo.snippet.description}</div>
            </Modal.Body>
            <Modal.Footer>
            <Button
              onClick={(e) => {
                setShowModal(false);
                }
              }>Close</Button>
            </Modal.Footer>
           </Modal>
        }

    </div>
  )
}

export default ProjectPage;

// class PlayList extends React.Component {
//     constructor(props) {
//       super(props);
//       this.state = {    
//         videos:[],
//         playList: null //props.playListItem
//       };
//       this.handleClick=this.handleClick.bind(this)
//     }

//     componentDidMount() {
//         //console.log("playList Item:" , this.state.playList.id)
//        //this.GetVideos();
//     }
//     handleClick = (video)=>{
//      // console.log("PLAYLIST: you clicked on ", video)
//       this.props.handleSelectedVideo(video);
//     }
//       GetVideos = async () => {
//         // console.log("searching ", this.state.playList);
//         const response = await youtube.get('/playlistItems', {
//             params: {
//                 part:"snippet",
//                 playlistId:this.state.playList.id,
//                 maxResults:50
//             }
//         })
//        //  console.log("searching ", response.data.items);
//         this.setState({
//             videos: response.data.items
//         })
//     };

//     render(){
//         return (
//             <section className="projectList">
//               <div>what!?</div>
//               {this.state.playList && 
//               <Container>
//               <Row>
//               <div style={{textTransform:"uppercase",paddingBottom:"10px"}}>{this.state.playList.snippet.title}</div>
//                   {
//                   this.state.videos.map((item,index) =>{
//                     // console.log("playlistitem",item);
//                     //<span key={"v" + index} href="#"  onClick={(e)=>{this.props.handleSelectedVideo(item)}} >
//                     return <ProjectCard handleClick={this.handleClick} itemData={item}  key={"v" + index} videoID={item.snippet.resourceId.videoId}  title={item.snippet.title} description={item.snippet.title} imgUrl={item.snippet.thumbnails.medium.url} ></ProjectCard>
//                   })
//               } 
//               </Row>
//               </Container>
//               }

//             </section>
//           )
//     }
// }

// export default PlayList;